import React from 'react'
import { Link } from 'gatsby'

const HomeChecklistSection = () => (
  <div className="bg-gray-100 w-full py-16">
    <div className="w-11/12 max-w-3xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>How much profit do you lose when your computer network is down?</h2>
      <h3 className='text-2xl md:text-3xl text-blue-900 text-center font-bold mb-8 italic'>Looking for the best IT Company?</h3>
      <p className='text-lg text-gray-800 text-center max-w-lg mx-auto'>Minimize your losses and stay one step ahead of potential disasters. We can help you with all of your IT services.</p>
      <div className='flex justify-center'>
        <Link to="/checklist">
          <button className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-xl text-center font-semibold tracking-wide rounded mt-8 shadow-lg">
            Download Disaster Recovery Checklist
          </button>
        </Link>
      </div>
    </div>
  </div>
)

export default HomeChecklistSection