import React from 'react'

const HomeCtaSection = () => (
  <div className="bg-gray-100 w-full py-16 border-b">
    <div className="w-11/12 max-w-3xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>IT Management Services</h2>
      <p className='text-lg text-gray-800 text-center max-w-2xl mx-auto'>IT Proactive provides excellent IT Service Management and IT Support to clients in the Costa Mesa, CA area. We help our clients with IT Support in Irvine. We also provide Outsourced IT Services in Orange County. We are Orange County best IT Company. Give us a call today for a free estimate on your next Information Technology (IT) project and we'll be able to assist you.</p>
      <div className='flex justify-center'>
        <a href="tel:9495430234" className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-xl text-center font-semibold tracking-wide rounded mt-8 shadow-lg">
          (949) 543-0234
        </a>
      </div>
    </div>
  </div>
)

export default HomeCtaSection